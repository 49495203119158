.recipe-manager {
  padding: 1rem;
  max-width: 1400px;
  margin: 0 auto;
  background: #f8fafc;
  min-height: 0;
  height: auto;
  padding-bottom: 1.5rem;
}

.recipe-header {
  padding: 20px 0;
  margin-bottom: 30px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.recipe-controls {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.control-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.control-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.search-input {
  flex: 2;
  padding: 0.6rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  transition: all 0.2s;
}

.category-select {
  flex: 1;
  padding: 0.6rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: white;
  font-size: 0.95rem;
  cursor: pointer;
}

.filter-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding-top: 0.5rem;
  border-top: 1px solid #e2e8f0;
}

.filter-btn, .sort-btn {
  padding: 0.6rem 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background: white;
  color: #64748b;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  white-space: nowrap;
}

.filter-btn:hover, .sort-btn:hover {
  background: #f8fafc;
  border-color: #cbd5e1;
}

.filter-btn.active {
  background: #dc2626;
  color: white;
  border-color: #dc2626;
}

.sort-btn.active {
  background: #3b82f6;
  color: white;
  border-color: #3b82f6;
}

.recipe-count {
  color: #6c757d;
  font-size: 14px;
  margin: 20px;
}

.recipe-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 1rem;
}

.recipe-card {
  position: relative;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  padding: 1.5rem;
  transition: all 0.3s ease;
  border: 1px solid #f1f5f9;
}

.recipe-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 20px -8px rgba(0, 0, 0, 0.12);
}

.recipe-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding: 0;
}

.recipe-name {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1.25rem;
  font-weight: 600;
  color: #1e293b;
  margin: 0;
  line-height: 1.4;
  flex-wrap: nowrap;
}

.recipe-metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  background: #f8fafc;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 1.25rem;
}

.metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.metric-label {
  font-size: 0.75rem;
  color: #64748b;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
}

.metric-value {
  font-size: 1.125rem;
  font-weight: 600;
  color: #334155;
}

.metric-value.markup-good {
  color: #16a34a;
}

.metric-value.markup-bad {
  color: #dc2626;
}

.recipe-ingredients {
  margin: 0 0 1.25rem 0;
  text-align: center;
}

.recipe-ingredients span {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  font-size: 0.875rem;
  color: #475569;
  padding: 0.625rem 1rem;
  background-color: #f1f5f9;
  border-radius: 8px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
}

.recipe-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.left-actions {
  display: flex;
  gap: 0.5rem;
}

.right-actions {
  display: flex;
  gap: 0.5rem;
}

.action-button {
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #666;
  font-size: 1.25rem;
}

.action-button:hover {
  transform: translateY(-1px);
}

.action-button.view {
  color: #2196F3;
}

.action-button.view:hover {
  background-color: rgba(33, 150, 243, 0.1);
}

.action-button.edit {
  color: #4CAF50;
}

.action-button.edit:hover {
  background-color: rgba(76, 175, 80, 0.1);
}

.action-button.delete {
  color: #f44336;
}

.action-button.delete:hover {
  background-color: rgba(244, 67, 54, 0.1);
}

.recipe-categories {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  margin: 20px;
}

.category-tag {
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 1;
}

.category-tag[data-category="Beverages"] {
  background-color: #e0f2fe;
  color: #0284c7;
}

.category-tag[data-category="Coffee"] {
  background-color: #ffedd5;
  color: #c2410c;
}

.category-tag[data-category="Breakfast Items"] {
  background-color: #fef9c3;
  color: #d97706;
}

.category-tag[data-category="Sandwiches & Wraps"] {
  background-color: #dcfce7;
  color: #15803d;
}

.category-tag[data-category="Salads & Soups"] {
  background-color: #d1fae5;
  color: #047857;
}

.category-tag[data-category="Main Courses"] {
  background-color: #fee2e2;
  color: #dc2626;
}

.category-tag[data-category="Sides"] {
  background-color: #f3e8ff;
  color: #9333ea;
}

.category-tag[data-category="Desserts"] {
  background-color: #fce7f3;
  color: #db2777;
}

.category-tag[data-category="Snacks"] {
  background-color: #bae6fd;
  color: #0369a1;
}

.category-tag[data-category="Specials"] {
  background-color: #fecaca;
  color: #b91c1c;
}

.category-tag[data-category="Kids' Menu"] {
  background-color: #ccfbf1;
  color: #0d9488;
}

.category-tag[data-category="Combo Meals"] {
  background-color: #ede9fe;
  color: #7c3aed;
}

.category-tag[data-category="Bakery"] {
  background-color: #fff7ed;
  color: #c2410c;
}

.category-tag[data-category="Uncategorized"] {
  background-color: #f1f5f9;
  color: #64748b;
}

.status-indicator {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
}

.recipe-title-row {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.recipe-title {
  margin: 0;
  font-size: 1.2rem;
  color: #2c3e50;
  text-align: left;
}

.status-icon {
  font-size: 1.25rem;
  flex-shrink: 0;
}

.status-icon.success {
  color: #16a34a;
}

.status-icon.error {
  color: #dc2626;
}

.recipe-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.recipe-card {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.recipe-card .recipe-category {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #e3f2fd;
  color: #1976d2;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.recipe-card .recipe-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.recipe-card .recipe-title {
  margin: 0;
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 8px;
}

.recipe-card .recipe-description {
  margin: 0;
  font-size: 14px;
  color: #666;
  line-height: 1.4;
}

.recipe-card .recipe-details {
  padding: 20px;
  flex-grow: 1;
}

.recipe-metrics {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.metric-item {
  text-align: center;
}

.metric-item label {
  display: block;
  font-size: 12px;
  color: #666;
  margin-bottom: 4px;
}

.metric-item .value {
  font-size: 16px;
  font-weight: 500;
  color: #2c3e50;
}

.recipe-category {
  display: inline-block;
  background: #f0f2f5;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 12px;
}

.recipe-description {
  margin: 0;
  color: #666;
  font-size: 0.95rem;
  line-height: 1.4;
  margin-bottom: 16px;
}

.recipe-actions {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.action-button.view {
  color: #3b82f6;
}

.action-button.view:hover {
  background-color: #dbeafe;
}

.action-button.edit {
  color: #059669;
}

.action-button.edit:hover {
  color: #4caf50;
  background-color: #d1fae5;
}

.action-button.delete {
  color: #dc2626;
}

.action-button.delete:hover {
  color: #ef4444;
  background-color: #fee2e2;
}

.page-title-container {
  margin: 0 0 2rem 0;
}

.page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #ffffff, #f8fafc);
  padding: 2rem 2.5rem;
  border-radius: 16px;
  font-size: 2.2rem;
  font-weight: 800;
  color: #1e293b;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border-left: 6px solid #3b82f6;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2.5rem;
  transition: all 0.3s ease;
}

.page-title h1 {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
  text-transform: inherit;
  letter-spacing: inherit;
}

.markup-good {
  background-color: rgba(34, 197, 94, 0.1);
  color: rgb(21, 128, 61);
  padding: 4px 8px;
  border-radius: 4px;
}

.markup-bad {
  background-color: rgba(239, 68, 68, 0.1);
  color: rgb(185, 28, 28);
  padding: 4px 8px;
  border-radius: 4px;
}

.recipe-category-tag {
  position: absolute;
  top: 12px;
  right: 12px;
  background: #e3f2fd;
  color: #1976d2;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}

.recipe-name {
  font-size: 18px;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  padding-right: 80px;
}

.metrics-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.metric-item {
  text-align: center;
}

.metric-label {
  font-size: 14px;
  color: #64748b;
  margin-bottom: 8px;
  font-weight: 500;
}

.metric-value {
  font-size: 18px;
  color: #1e293b;
  font-weight: 600;
}

.metric-value.markup {
  color: #ef4444;
}

.metric-value.markup.good {
  color: #22c55e;
}

.metric-value.currency::before {
  content: '₹';
}

.page-title-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.recipe-stats {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pending-count {
  background: #fee2e2;
  color: #dc2626;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  font-size: 0.875rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.pending-count::before {
  content: "⚠️";
  font-size: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .recipe-controls {
    gap: 12px;
  }
  
  .search-input,
  .category-select,
  .sort-button,
  .status-indicator {
    width: 100%;
  }

  .recipe-list {
    grid-template-columns: 1fr;
    padding: 16px;
  }
}

@media (max-width: 1024px) {
  .control-section {
    flex-wrap: wrap;
  }

  .search-input {
    flex: 1 1 100%;
  }

  .category-select {
    flex: 1 1 auto;
  }

  .separator {
    display: none;
  }

  .sort-buttons {
    flex: 1 1 auto;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}
