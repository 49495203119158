.ingredients-manager-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
  background: #f8fafc;
  min-height: 0;
  height: auto;
  padding-bottom: 1.5rem;
  overflow: hidden;
}

.page-title-container {
  margin: 0 0 1.5rem 0;
}

.page-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #ffffff, #f0fdf4);
  padding: 1.5rem 2rem;
  border-radius: 12px;
  font-size: 1.8rem;
  font-weight: 700;
  color: #1e293b;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  border-left: 4px solid #22c55e;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 1.5rem;
  transition: all 0.3s ease;
}

.ingredients-manager {
  background: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 1.5rem;
}

.header-section {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  padding: 0.25rem 1rem;
}

.header-section h2 {
  text-align: center;
  color: #1f2937;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0;
}

.data-buttons {
  display: flex;
  gap: 0.5rem;
  justify-self: end;
}

.search-wrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-self: start;
}

.ingredients-header {
  text-align: center;
  padding: 1rem;
  margin-bottom: 1.5rem;
}

.ingredients-header h2 {
  color: #22c55e;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.add-ingredient-form {
  background: white;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
}

.actions-card {
  background: white;
  border-radius: 8px;
  padding: 0.75rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 0.5rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
  margin: 0;
}

.form-label {
  font-size: 0.9rem;
  color: #4b5563;
  margin-bottom: 0;
}

.form-input {
  padding: 0.4rem 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 0.9rem;
}

.form-input:focus {
  border-color: #22c55e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(34, 197, 94, 0.1);
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-buttons-left {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.form-buttons-right {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.btn-add {
  background-color: #22c55e;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.2s;
  min-width: 160px;
  box-shadow: 0 3px 6px rgba(34, 197, 94, 0.25);
}

.btn-add:hover {
  background-color: #16a34a;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(34, 197, 94, 0.3);
}

.search-container {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.search-input {
  width: 200px;
  padding: 0.5rem 0.75rem;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 0.9rem;
}

.search-input:focus {
  border-color: #22c55e;
  outline: none;
  box-shadow: 0 0 0 2px rgba(34, 197, 94, 0.1);
}

.btn-search {
  background-color: #22c55e;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-search:hover {
  background-color: #16a34a;
  transform: translateY(-1px);
}

.btn-search:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
  transform: none;
}

.neo-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.5rem;
}

.form-row {
  display: flex;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}

.flex-1 {
  flex: 1;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  margin-bottom: 0.75rem;
}

.form-label {
  display: block;
  margin-bottom: 0.25rem;
  font-weight: 500;
  color: #374151;
}

.form-input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  background-color: #fff;
  font-size: 0.9rem;
  transition: border-color 0.15s ease-in-out;
}

.form-input:focus {
  outline: none;
  border-color: #22c55e;
  box-shadow: 0 0 0 2px rgba(34, 197, 94, 0.1);
}

.form-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

.ingredients-table {
  width: 100%;
  border-spacing: 0;
  margin-top: 1rem;
  position: relative;
  overflow: hidden;
}

.ingredients-table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.ingredients-table th {
  background-color: #f9fafb;
  font-weight: 600;
  color: #374151;
  text-align: left;
  padding: 12px;
  border-bottom: 2px solid #e5e7eb;
  position: relative;
  white-space: nowrap;
}

.ingredients-table td {
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ingredients-table th:nth-child(1),
.ingredients-table td:nth-child(1) {
  width: 30%;
}

.ingredients-table th:nth-child(2),
.ingredients-table td:nth-child(2) {
  width: 15%;
}

.ingredients-table th:nth-child(3),
.ingredients-table td:nth-child(3) {
  width: 15%;
}

.ingredients-table th:nth-child(4),
.ingredients-table td:nth-child(4) {
  width: 25%;
}

.ingredients-table th:nth-child(5),
.ingredients-table td:nth-child(5) {
  width: 15%;
  text-align: center;
}

.modern-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  overflow: hidden;
}

.modern-table th {
  background-color: #22c55e;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
}

.modern-table th,
.modern-table td {
  padding: 8px 16px;
  text-align: left;
  vertical-align: middle;
}

.modern-table th:first-child,
.modern-table td:first-child {
  width: 30%;
}

.modern-table th:nth-child(2),
.modern-table td:nth-child(2) {
  width: 10%;
}

.modern-table th:nth-child(3),
.modern-table td:nth-child(3) {
  width: 15%;
  text-align: right;
}

.modern-table th:nth-child(4),
.modern-table td:nth-child(4) {
  width: 35%;
}

.modern-table th:last-child,
.modern-table td:last-child {
  text-align: center;
  padding: 8px;
  width: 100px;
  position: relative;
}

.modern-table td:last-child {
  text-align: center;
  padding: 8px;
  width: 100px;
  vertical-align: middle;
  position: relative;
}

.modern-table th:last-child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  width: 100px;
}

.modern-table td:last-child {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
  width: 100px;
  vertical-align: middle;
}

.modern-table th:last-child,
.modern-table td:last-child {
  padding-left: 0;
  padding-right: 0;
}

.action-buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.edit-btn,
.delete-btn,
.save-btn,
.cancel-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0 2px;
  border: none;
  border-radius: 6px;
  background: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 16px;
  flex-shrink: 0;
}

.edit-btn:hover {
  background-color: rgba(59, 130, 246, 0.1);
  transform: translateY(-1px);
}

.edit-btn:hover span {
  transform: scale(1.1);
}

.delete-btn:hover {
  background-color: rgba(239, 68, 68, 0.1);
  transform: translateY(-1px);
}

.delete-btn:hover span {
  transform: scale(1.1);
}

.save-btn:hover {
  background-color: rgba(34, 197, 94, 0.1);
  transform: translateY(-1px);
}

.save-btn:hover span {
  transform: scale(1.1);
}

.cancel-btn:hover {
  background-color: rgba(239, 68, 68, 0.1);
  transform: translateY(-1px);
}

.cancel-btn:hover span {
  transform: scale(1.1);
}

.edit-btn span,
.delete-btn span,
.save-btn span,
.cancel-btn span {
  transition: transform 0.2s ease;
}

.btn {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
}

.btn-success {
  background-color: #10b981;
  color: white;
}

.btn-success:hover {
  background-color: #059669;
  transform: translateY(-1px);
}

.btn-danger {
  background-color: #ef4444;
  color: white;
  padding: 0.5rem 1rem;
}

.btn-danger:hover {
  background-color: #dc2626;
  transform: translateY(-1px);
}

.btn-secondary {
  background-color: #6b7280;
  color: white;
}

.btn-secondary:hover {
  background-color: #4b5563;
  transform: translateY(-1px);
}

.error-message {
  color: #dc2626;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.ingredients-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.ingredient-card {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  padding: 1rem;
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.18);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 0.75rem;
}

.ingredient-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 10px 40px rgba(31, 38, 135, 0.15);
}

.ingredient-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
}

.ingredient-header h3 {
  font-size: 1.15rem;
  color: #2c3e50;
  margin: 0;
  font-weight: 600;
  line-height: 1.4;
}

.cost-badge {
  background: rgba(46, 204, 113, 0.1);
  color: #27ae60;
  padding: 0.4rem 1rem;
  border-radius: 20px;
  font-size: 0.85rem;
  font-weight: 500;
  white-space: nowrap;
}

.ingredient-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
}

.detail-item {
  background: rgba(255, 255, 255, 0.7);
  padding: 0.75rem;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.detail-item label {
  display: block;
  font-size: 0.85rem;
  color: #7f8c8d;
  margin-bottom: 0.25rem;
}

.detail-item span {
  font-size: 1.05rem;
  font-weight: 500;
  color: #2c3e50;
}

.card-actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  margin-top: auto;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-actions button {
  padding: 0.5rem;
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.icon-btn {
  background-color: #22c55e;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-btn:hover {
  background-color: #16a34a;
  transform: translateY(-1px);
}

.btn-icon {
  width: 1.25rem;
  height: 1.25rem;
  filter: brightness(0) invert(1);
}

.btn-add {
  background: rgba(52, 152, 219, 0.1);
  color: #2980b9;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  font-size: 0.85rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-add:hover {
  background: rgba(52, 152, 219, 0.2);
}

.btn-edit {
  background: rgba(46, 204, 113, 0.1);
  color: #27ae60;
}

.btn-edit:hover {
  background: rgba(46, 204, 113, 0.2);
}

.btn-delete {
  background: rgba(231, 76, 60, 0.1);
  color: #c0392b;
}

.btn-delete:hover {
  background: rgba(231, 76, 60, 0.2);
}

.btn-save {
  background: rgba(46, 204, 113, 0.1);
  color: #27ae60;
}

.btn-save:hover {
  background: rgba(46, 204, 113, 0.2);
}

.btn-cancel {
  background: rgba(149, 165, 166, 0.1);
  color: #7f8c8d;
}

.btn-cancel:hover {
  background: rgba(149, 165, 166, 0.2);
}

.edit-form {
  width: 100%;
}

.form-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  gap: 0.75rem;
}

.actions-left {
  display: flex;
  align-items: center;
}

.actions-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.search-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.search-input {
  padding: 0.5rem 2.25rem 0.5rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 0.9rem;
  width: 200px;
  transition: all 0.2s;
  background-color: white;
  color: #1f2937;
  height: 38px;
}

.search-input:focus {
  outline: none;
  border-color: #10b981;
  box-shadow: 0 0 0 2px rgba(16, 185, 129, 0.1);
}

.search-input::placeholder {
  color: #9ca3af;
}

.search-button {
  height: 38px;
  padding: 0 1rem;
  white-space: nowrap;
}

.search-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.search-clear-btn {
  position: absolute;
  right: 4.75rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #9ca3af;
  font-size: 1.15rem;
  cursor: pointer;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
  z-index: 1;
}

.search-clear-btn:hover {
  color: #4b5563;
}

.toast-message {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 24px;
  border-radius: 8px;
  z-index: 1000;
  animation: slideIn 0.3s ease-in-out;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.toast-message.success {
  background-color: #22c55e;
  color: white;
  border-left: 4px solid #16a34a;
}

.toast-message.error {
  background-color: #ef4444;
  color: white;
  border-left: 4px solid #dc2626;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.edit-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: #f8fafc;
  font-size: 0.875rem;
  color: #1e293b;
  transition: all 0.2s;
  outline: none;
}

.edit-input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
  background-color: #fff;
}

.edit-input:hover {
  border-color: #94a3b8;
}

select.edit-input {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236B7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

td .edit-input {
  max-width: 150px;
  min-width: 80px;
}

.table-container {
  width: 100%;
  overflow: hidden;
}

.ingredient-form {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  max-width: 600px;
}

.ingredient-form .form-group {
  margin-bottom: 1.25rem;
}

.ingredient-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #2c3e50;
}

.ingredient-form input,
.ingredient-form select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 1rem;
  transition: all 0.2s ease;
}

.ingredient-form input:focus,
.ingredient-form select:focus {
  border-color: #3b82f6;
  outline: none;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.ingredient-form input.error,
.ingredient-form select.error {
  border-color: #ef4444;
}

.ingredient-form input.error:focus,
.ingredient-form select.error:focus {
  box-shadow: 0 0 0 3px rgba(239, 68, 68, 0.1);
}

.ingredient-form .error-message {
  color: #ef4444;
  font-size: 0.875rem;
  margin-top: 0.375rem;
  display: block;
}

.ingredient-form button {
  width: 100%;
  padding: 0.75rem;
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.ingredient-form button:hover:not(:disabled) {
  background-color: #2563eb;
  transform: translateY(-1px);
}

.ingredient-form button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
  transform: none;
}

.search-input-container {
  position: relative;
  width: 100%;
}

.suggestions-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.suggestion-item {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.suggestion-item:hover {
  background-color: #f5f5f5;
}

@media (max-width: 768px) {
  .ingredients-manager {
    padding: 0.75rem;
  }

  .ingredients-grid {
    grid-template-columns: 1fr;
  }

  .ingredient-card {
    padding: 0.75rem;
  }

  .ingredient-header {
    flex-direction: column;
    align-items: center;
  }

  .ingredient-header h3 {
    margin-bottom: 0.25rem;
  }

  .ingredient-details {
    grid-template-columns: 1fr;
  }

  .detail-item {
    padding: 0.5rem;
  }

  .card-actions {
    grid-template-columns: 1fr;
    gap: 0.25rem;
  }

  .card-actions button {
    padding: 0.25rem;
  }

  .search-container {
    width: 100%;
  }
  
  .search-input {
    width: 100%;
  }
  
  .btn-success {
    width: 100%;
  }

  .form-actions {
    flex-direction: column;
    gap: 0.75rem;
  }

  .actions-left,
  .actions-right,
  .search-wrapper {
    width: 100%;
  }

  .search-input {
    flex: 1;
  }

  .search-button {
    white-space: nowrap;
  }

  .btn-success {
    width: 100%;
  }

  .header-section {
    grid-template-columns: 1fr;
    gap: 0.75rem;
    text-align: center;
  }

  .data-buttons,
  .search-wrapper {
    justify-self: center;
  }

  .form-buttons {
    flex-direction: column;
    gap: 0.75rem;
  }

  .form-buttons-left,
  .form-buttons-right {
    width: 100%;
  }

  .search-container {
    width: 100%;
  }

  .search-input {
    flex: 1;
    min-width: unset;
  }

  .btn-add,
  .btn-search {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .ingredients-manager {
    padding: 0.75rem;
  }

  .header-section {
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
  }

  .data-buttons {
    justify-content: stretch;
  }

  .data-buttons button {
    flex: 1;
  }

  .form-grid {
    grid-template-columns: 1fr;
  }

  .modern-table {
    font-size: 0.85rem;
  }

  .modern-table th,
  .modern-table td {
    padding: 0.5rem;
  }
}
