.image-preview-container {
  position: relative;
  width: 100%;
  height: 300px;
  border-radius: 12px;
  overflow: hidden;
  background: #f8fafc;
  margin-bottom: 1rem;
}

.image-preview-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #f8fafc;
  transition: transform 0.2s ease;
}

/* Image Actions */
.image-actions {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.image-preview-container:hover .image-actions {
  opacity: 1;
}

.image-action-btn {
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 8px;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.image-action-btn:hover {
  background: white;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.image-action-btn.remove {
  color: #ef4444;
}

.image-action-btn.fullscreen {
  color: #3b82f6;
}

.image-action-btn svg {
  width: 16px;
  height: 16px;
}

/* Enlarged Image View - completely separate from preview */
.enlarged-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.98);
  z-index: 9999;
  display: grid;
  place-items: center;
}

.enlarged-image-container {
  max-width: 90vw;
  max-height: 90vh;
  position: relative;
}

.enlarged-image-container img {
  max-width: 100%;
  max-height: 90vh;
  object-fit: contain;
}

.close-enlarged-btn {
  position: fixed;
  top: 20px;
  right: 20px;
  background: #fff;
  border: none;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  z-index: 10000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.close-enlarged-btn:hover {
  background: #fee2e2;
  transform: scale(1.05);
}

.close-enlarged-btn svg {
  width: 28px;
  height: 28px;
  color: #ef4444;
}

.close-enlarged-btn:hover svg {
  color: #dc2626;
}

/* Main container */
.recipe-form-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  background: #f8fafc;
  min-height: 0;
  height: auto;
}

/* Page Title */
.page-title-container {
  margin: 0 0 2rem 0;
}

.page-title {
  background: linear-gradient(135deg, #ffffff 0%, #f8fafc 100%);
  padding: 2rem 2.5rem;
  border-radius: 16px;
  font-size: 2.2rem;
  font-weight: 800;
  color: #1e293b;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  border-left: 6px solid #3b82f6;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2rem;
  transition: all 0.3s ease;
}

.page-title:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.12);
}

/* Form Layout */
.recipe-form {
  display: grid;
  grid-template-columns: 1fr 1.2fr;
  grid-template-areas: 
    "recipe-details preparation"
    "ingredients preparation"
    "mrp-decider menu-availability"
    "action-buttons action-buttons";
  gap: 2rem;
  min-height: 0;
  height: auto;
  padding-bottom: 2rem;
}

/* Common Section Styles */
.form-section {
  background: #ffffff;
  border-radius: 20px;
  padding: 1rem 2rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
}

.form-section h2 {
  color: #1e293b;
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 1.5rem 0;
  padding-bottom: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-bottom: 2px solid #f1f5f9;
}

.form-section h2::after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 60px;
  height: 2px;
  background: linear-gradient(90deg, #3b82f6, #60a5fa);
}

/* Add icons to section titles */
.recipe-details-section h2::before {
  content: '📝';
}

.preparation-section h2::before {
  content: '👨‍🍳';
}

.ingredients-section h2::before {
  content: '🥗';
}

.mrp-section h2::before {
  content: '💰';
}

.menu-availability-section h2::before {
  content: '🍽️';
}

/* Add padding to section content */
.form-section > *:not(h2) {
  padding-top: 0.5rem;
}

/* MRP and Menu Availability Sections */
.mrp-section,
.menu-availability-section {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  height: 100%;
}

.menu-availability-section .menu-toggles {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mrp-section .form-group:last-child {
  margin-bottom: 0;
}

.menu-availability-section .menu-toggles .checkbox-item:last-child {
  margin-bottom: 0;
}

/* Form Controls */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #475569;
  font-size: 0.95rem;
}

.form-control {
  width: 100%;
  padding: 0.875rem 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background: #f8fafc;
}

.form-control:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.1);
  outline: none;
  background: #ffffff;
}

.form-control.error-input {
  border-color: #ef4444;
  background-color: #fef2f2;
}

.form-control.error-input:focus {
  border-color: #ef4444;
  box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}

.error-message {
  color: #ef4444;
  font-size: 0.875rem;
  margin: 8px 0;
  padding: 0;
}

.form-control:disabled {
  background-color: #f3f4f6;
  cursor: not-allowed;
  opacity: 0.8;
  border-color: #d1d5db;
}

.form-control:disabled:hover {
  border-color: #d1d5db;
}

.submit-button:disabled {
  background-color: #9ca3af;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Styled Checkbox */
.styled-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 1rem;
  user-select: none;
  display: inline-block;
  color: #1e293b;
  line-height: 25px;
}

.checkbox-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border: 2px solid #e2e8f0;
  background: #fff;
  border-radius: 6px;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

.styled-checkbox:hover + .checkbox-label:before {
  border-color: #3b82f6;
}

.styled-checkbox:focus + .checkbox-label:before {
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

.styled-checkbox:checked + .checkbox-label:before {
  background: #3b82f6;
  border-color: #3b82f6;
}

.styled-checkbox:checked + .checkbox-label:after {
  content: '';
  position: absolute;
  left: 9px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

/* Menu Availability Section */
.menu-availability-section .checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.menu-availability-section .checkbox-item {
  position: relative;
  padding: 0.75rem;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.menu-availability-section .checkbox-item:hover {
  background-color: #f8fafc;
}

/* Recipe Details Section */
.recipe-details-section {
  grid-area: recipe-details;
}

.recipe-details-section .form-group {
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.recipe-details-section .form-group:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.recipe-details-section label {
  display: block;
  color: #1e293b;
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.recipe-details-section .form-control {
  width: 100%;
  padding: 0.875rem 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.2s ease;
  background: #f8fafc;
}

.recipe-details-section .form-control:hover {
  border-color: #3b82f6;
  background: #fff;
}

.recipe-details-section .form-control:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  outline: none;
  background: #fff;
}

/* Image Upload Section */
.image-upload-section {
  position: relative;
  margin-top: 1rem;
}

.image-upload-container {
  position: relative;
  width: 100%;
  min-height: 200px;
  max-height: 300px;
  border: 2px dashed #e2e8f0;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8fafc;
  transition: all 0.3s ease;
}

.image-upload-container:hover {
  border-color: #3b82f6;
  background: #f0f7ff;
}

.image-upload-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  text-align: center;
}

.image-upload-placeholder svg {
  width: 3rem;
  height: 3rem;
  color: #3b82f6;
}

.image-upload-placeholder p {
  color: #64748b;
  font-size: 0.9rem;
}

/* Preparation Procedure Section */
.preparation-section {
  grid-area: preparation;
  min-height: 500px;
}

.preparation-section h3 {
  margin: 0 0 1rem 0;
}

.preparation-content {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.25rem;
}

.preparation-item {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.25rem;
  background: #f8fafc;
  border-radius: 12px;
  border: 1.5px solid #e2e8f0;
  transition: all 0.2s ease;
}

.preparation-item:hover {
  border-color: #3b82f6;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transform: translateY(-2px);
}

.preparation-item-header {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.preparation-item-header span {
  font-size: 1.5rem;
}

.preparation-item-header label {
  font-size: 1.1rem;
  font-weight: 500;
  color: #1e293b;
}

.preparation-item textarea {
  width: 100%;
  min-height: 120px;
  padding: 1rem;
  border: 1.5px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  line-height: 1.5;
  resize: vertical;
  background: white;
  transition: all 0.2s ease;
}

.preparation-item textarea:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  outline: none;
  background: #fff;
}

.preparation-item textarea::placeholder {
  color: #94a3b8;
}

/* Ingredients Section */
.ingredients-section {
  grid-area: ingredients;
  padding-top: 0.5rem;
}

.ingredients-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.ingredients-header h3 {
  margin: 0;
  padding: 0 0 0.75rem 0;
  font-size: 1.4rem;
  color: #1e293b;
  font-weight: 600;
  line-height: 1;
}

/* Add Ingredient Form */
.add-ingredient-form {
  background: #f8f9fa;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.input-row {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  align-items: end;
}

.ingredient-select {
  min-width: 200px;
}

.quantity-input {
  width: 100%;
}

.add-ingredient-btn {
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 6px;
  font-weight: 500;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  width: 100%;
}

.add-ingredient-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

.add-ingredient-btn svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* Form Groups */
.form-group {
  margin-bottom: 0;
}

.form-group label {
  display: block;
  margin-bottom: 0.35rem;
  font-size: 1rem;
  font-weight: 500;
  color: #1e293b;
}

/* Ingredients Table */
.ingredients-table-container {
  margin: 0;
  padding: 0;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.ingredients-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin: 0;
  padding: 0;
}

.ingredients-table th {
  background-color: #f0f9ff;
  padding: 12px 16px;
  text-align: left;
  font-weight: 600;
  font-size: 0.95rem;
  border-bottom: 1px solid #e5e7eb;
}

.ingredients-table td {
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  vertical-align: middle;
  background-color: #ffffff;
  height: 48px;
  box-sizing: border-box;
}

/* Column widths */
.ingredients-table th:nth-child(1),
.ingredients-table td:nth-child(1) {
  width: 40%;
  padding-left: 16px;
}

.ingredients-table th:nth-child(2),
.ingredients-table td:nth-child(2) {
  width: 22.5%;
}

.ingredients-table th:nth-child(3),
.ingredients-table td:nth-child(3) {
  width: 22.5%;
  text-align: right;
  padding-right: 24px;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

.ingredients-table th:last-child,
.ingredients-table td:last-child {
  width: 15%;
  text-align: center;
  padding: 0;
  position: relative;
}

.remove-ingredient-btn {
  padding: 0;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: background-color 0.2s ease;
}

.remove-ingredient-btn:hover {
  background-color: rgba(239, 68, 68, 0.1);
}

.remove-ingredient-btn svg {
  width: 16px;
  height: 16px;
  stroke-width: 2.5;
  color: #ef4444;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.ingredient-name {
  font-weight: 500;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

.ingredient-quantity {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

.ingredient-cost {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 0.95rem;
}

.remove-ingredient-btn {
  padding: 4px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ef4444;
  transition: background-color 0.2s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.remove-ingredient-btn:hover {
  background-color: rgba(239, 68, 68, 0.1);
}

.remove-ingredient-btn svg {
  width: 16px;
  height: 16px;
  stroke-width: 2.5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Total Cost Section */
.ingredients-total {
  margin-top: 1rem;
  padding: 1rem 1.25rem;
  background: #f8fafc;
  border-radius: 8px;
  border: 1.5px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.total-label {
  font-weight: 600;
  color: #1e293b;
}

.total-amount {
  font-size: 1.1rem;
  font-weight: 600;
  color: #0f172a;
}

/* MRP Decider Section */
.mrp-section {
  grid-area: mrp-decider;
}

/* Menu Availability Section */
.menu-availability-section {
  grid-area: menu-availability;
}

/* Action Buttons Section */
.action-buttons-card {
  grid-area: action-buttons;
  background: #ffffff;
  border-radius: 20px;
  padding: 1.75rem;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  gap: 1.25rem;
  margin-top: 1.5rem;
}

/* Common Button Styles */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  padding: 1rem 1.75rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
  min-width: 160px;
  height: 48px;
  border: 2px solid transparent;
  box-sizing: border-box;
}

.btn span {
  font-size: 1.25rem;
  font-weight: 600;
}

/* Create Button */
.create-btn {
  background: #3b82f6;
  color: white;
}

.create-btn:hover:not(:disabled) {
  background: #2563eb;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.2);
}

.create-btn:disabled {
  background: #94a3b8;
  cursor: not-allowed;
}

/* Cancel Button */
.cancel-btn {
  background: white;
  color: #ef4444;
  border: 2px solid #ef4444;
}

.cancel-btn:hover {
  background: #fef2f2;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(239, 68, 68, 0.1);
}

/* Return Button */
.return-btn {
  background: white;
  color: #64748b;
  border: 2px solid #e2e8f0;
}

.return-btn:hover {
  background: #f8fafc;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

/* Financial Metrics */
.financial-metrics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.metric-card {
  background: #f8fafc;
  padding: 1rem;
  border-radius: 16px;
  text-align: center;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
}

.metric-card.metric-success {
  background: #f0fdf4;
  border-color: #86efac;
}

.metric-card.metric-success .metric-value {
  color: #15803d;
}

.metric-card.metric-warning {
  background: #fff1f2;
  border-color: #fecdd3;
}

.metric-card.metric-warning .metric-value {
  color: #be123c;
}

.metric-card:hover {
  background: #f1f5f9;
  transform: translateY(-2px);
}

.metric-label {
  color: #64748b;
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.metric-value {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 700;
}

/* Responsive Design */
@media (max-width: 768px) {
  .recipe-form {
    grid-template-columns: 1fr;
    grid-template-areas: 
      "recipe-details"
      "preparation"
      "ingredients"
      "mrp-decider"
      "menu-availability"
      "action-buttons";
    gap: 1.5rem;
  }

  .page-title {
    font-size: 1.6rem;
    padding: 1.2rem 1.8rem;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-section,
  .financial-metrics-card {
    padding: 1.2rem;
    margin-bottom: 0.75rem;
  }
  
  .page-title-container {
    margin: 1rem 0;
  }
  
  .page-title {
    font-size: 1.6rem;
    padding: 1.2rem 1.8rem;
    margin-bottom: 1.5rem;
  }
  
  .notification {
    margin: 0.4rem 0;
  }
  
  .ingredient-item {
    margin: 0.2rem 0;
  }
}

/* Common card styling */
.card-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.03);
  border-radius: 12px;
  background: white;
}

.financial-metrics-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.03);
  padding: 1.8rem;
  border-radius: 12px;
  background: white;
}

.ingredient-item {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.03);
  padding: 1rem;
  margin: 0.25rem 0;
  border-radius: 12px;
  background: white;
}

/* Notification Styles */
.notification {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.03);
  margin: 0.5rem 0;
  padding: 1rem 1.5rem;
  animation: slideIn 0.3s ease-out;
  z-index: 1000;
}

.notification.success {
  background-color: #f0fdf4;
  border: 1px solid #dcfce7;
  color: #059669;
}

.notification.error {
  background-color: #fef2f2;
  border: 1px solid #fee2e2;
  color: #dc2626;
}

.notification-icon {
  font-size: 1.2em;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.notification.hide {
  animation: slideOut 0.3s ease-in forwards;
}

@media (max-width: 768px) {
  .notification {
    top: 10px;
    right: 10px;
    left: 10px;
    padding: 12px 16px;
  }
}

/* Category Dropdown Styles */
.form-group select.form-control {
  appearance: none;
  background-color: white;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.form-group select.form-control option {
  padding: 12px;
  font-size: 14px;
}

/* Distinct colors for each category */
.form-group select.form-control option[value="food"] {
  background-color: #f0fdf4;  /* Light green */
}

.form-group select.form-control option[value="bakery"] {
  background-color: #fef2f2;  /* Light red */
}

.form-group select.form-control option[value="beverage"] {
  background-color: #f0f9ff;  /* Light blue */
}

.form-group select.form-control:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

/* Image Upload Styles */
.image-upload-container {
  margin: 15px 0;
  width: 100%;
}

.image-preview-container {
  position: relative;
  display: inline-block;
  margin: 10px 0;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 4px;
  background: #fff;
}

.image-preview {
  margin-top: 15px;
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.preview-image {
  max-width: 100%;
  height: auto;
  display: block;
}

.remove-image-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 18px;
  padding: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.2s ease;
}

.remove-image-btn:hover {
  background-color: #c82333;
  transform: scale(1.1);
}

.file-input-container {
  width: 100%;
  margin: 10px 0;
}

.file-input-container input[type="file"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
}

.file-input-container input[type="file"]:hover {
  border-color: #80bdff;
}

/* Delivery Image Section */
.delivery-image-section {
  margin-top: 1.5rem;
  opacity: 0;
  transform: translateY(-10px);
  animation: slideDown 0.3s ease forwards;
}

@keyframes slideDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Toast Notifications */
.toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 99999;
  width: auto;
  max-width: 100%;
  pointer-events: none;
}

.toast {
  background: #333;
  padding: 16px 24px;
  margin-bottom: 16px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  min-width: 300px;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  opacity: 1;
  transform: translateX(0);
  transition: all 0.3s ease;
  pointer-events: auto;
}

.toast-success {
  background: #4caf50;
  border-left: 6px solid #2e7d32;
}

.toast-error {
  background: #f44336;
  border-left: 6px solid #c62828;
}

.toast-warning {
  background: #ff9800;
  border-left: 6px solid #ef6c00;
}

@keyframes slideIn {
  0% {
    transform: translateX(120%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.toast {
  animation: slideIn 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.toast-icon {
  font-size: 1.25rem;
  flex-shrink: 0;
}

.toast-success .toast-icon {
  color: #10b981;
}

.toast-warning .toast-icon {
  color: #f59e0b;
}

.toast-error .toast-icon {
  color: #ef4444;
}

.toast-message {
  color: #1e293b;
  font-size: 0.95rem;
  font-weight: 500;
  margin-right: auto;
}

.toast-close {
  color: #94a3b8;
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  font-size: 1.25rem;
  line-height: 1;
  transition: color 0.2s ease;
}

.toast-close:hover {
  color: #64748b;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.toast-exit {
  animation: slideOut 0.3s ease-in forwards;
}

/* Toast Styles */
.toast-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.toast {
  padding: 12px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  color: white;
  font-size: 14px;
  min-width: 200px;
  animation: slideIn 0.3s ease-in-out;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.toast-success {
  background-color: #4caf50;
}

.toast-error {
  background-color: #f44336;
}

.toast-warning {
  background-color: #ff9800;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Two-column layout */
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
}

/* Card styles */
.form-section {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-section h3 {
  margin-bottom: 20px;
  color: #333;
  font-size: 1.2rem;
  font-weight: 600;
}

/* Menu Availability Section */
.menu-availability-section {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.menu-availability-section .checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.delivery-image-section {
  margin-top: 20px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.delivery-image-section h4 {
  font-size: 1rem;
  color: #333;
  margin-bottom: 15px;
}

.image-preview {
  margin-top: 15px;
  max-width: 100%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.preview-image {
  width: 100%;
  height: auto;
  display: block;
}

/* Ensure both cards have equal height */
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: stretch;
}

.col-md-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  flex-direction: column;
}

.form-section {
  flex: 1;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Form Action Buttons */
.form-actions {
  display: flex;
  gap: 15px;
  justify-content: center;
  padding: 30px 0;
  margin-top: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-actions button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 6px;
  transition: all 0.3s ease;
  min-width: 160px;
}

.form-actions .create-button {
  background: #2ecc71;
  color: white;
  border: none;
}

.form-actions .create-button:hover {
  background: #27ae60;
  transform: translateY(-2px);
}

.form-actions .create-button:disabled {
  background: #95a5a6;
  cursor: not-allowed;
  transform: none;
}

.form-actions .reset-button {
  background: #3498db;
  color: white;
}

.form-actions .reset-button:hover {
  background: #2980b9;
  transform: translateY(-2px);
}

.form-actions .cancel-button {
  background: #e74c3c;
  color: white;
}

.form-actions .cancel-button:hover {
  background: #c0392b;
  transform: translateY(-2px);
}

/* Add loading spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.fa-spinner {
  animation: spin 1s linear infinite;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-actions {
    flex-direction: column;
    padding: 20px;
  }

  .form-actions button {
    width: 100%;
    justify-content: center;
  }
  
  .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Two-column layout for MRP Decider and Menu Availability */
.pricing-menu-section {
  display: flex;
  gap: 30px;
  margin: 30px 0;
}

.pricing-menu-section .col-md-6 {
  flex: 1;
  min-width: 0;
}

.pricing-menu-section .form-section {
  background: white;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.pricing-menu-section h3 {
  color: #2c3e50;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 2px solid #3498db;
}

/* MRP Decider specific styles */
.financial-section .form-group {
  margin-bottom: 20px;
}

.financial-section label {
  display: block;
  font-weight: 500;
  margin-bottom: 8px;
  color: #34495e;
}

.financial-section input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.financial-section input:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.1);
}

.financial-metrics {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.metric-card {
  background: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
}

.metric-label {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
}

.metric-value {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c3e50;
}

/* Menu Availability specific styles */
.menu-availability-section .checkbox-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.checkbox-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.checkbox-item:hover {
  background: #e9ecef;
}

.checkbox-item input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: 2px solid #3498db;
  border-radius: 4px;
}

.checkbox-item label {
  margin: 0;
  cursor: pointer;
  font-size: 1rem;
  color: #34495e;
  font-weight: 500;
}

/* Delivery Image Section */
.delivery-image-section {
  margin-top: 25px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.delivery-image-section h4 {
  font-size: 1.1rem;
  color: #2c3e50;
  margin-bottom: 15px;
}

.image-preview {
  margin-top: 15px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.preview-image {
  width: 100%;
  height: auto;
  display: block;
}

/* Form Action Buttons Container */
.form-actions-container {
  width: 100%;
  padding: 25px 0;
  margin-top: 30px;
  background: #f8f9fa;
  border-top: 1px solid #e9ecef;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .pricing-menu-section {
    flex-direction: column;
    gap: 20px;
  }

  .financial-metrics {
    grid-template-columns: 1fr;
  }
}

/* Recipe Ingredients Section */
.recipe-ingredients-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.ingredients-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  max-width: 100%;
  overflow-x: hidden;
  width: 90%;
  margin: 0 auto;
}

.ingredient-row {
  display: grid;
  grid-template-columns: minmax(150px, 2fr) minmax(80px, 1fr) minmax(100px, 1fr) 100px;
  gap: 1rem;
  align-items: center;
  padding: 0.75rem 0;
  border-bottom: 1px solid #e2e8f0;
  position: relative;
}

.remove-ingredient-btn {
  position: absolute;
  right: 20px;
  padding: 0.5rem;
  background: #fee2e2;
  border: none;
  border-radius: 8px;
  color: #ef4444;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
}

.remove-ingredient-btn:hover {
  background-color: rgba(239, 68, 68, 0.1);
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
  .ingredients-card {
    width: 92%;
  }
  
  .ingredient-row {
    grid-template-columns: minmax(120px, 2fr) minmax(70px, 1fr) minmax(90px, 1fr) 100px;
    gap: 0.75rem;
  }
  
  .remove-ingredient-btn {
    right: 15px;
  }
}

@media screen and (max-width: 768px) {
  .ingredients-card {
    width: 95%;
  }
  
  .ingredient-row {
    grid-template-columns: 1fr 80px 90px 100px;
    gap: 0.5rem;
  }
  
  .remove-ingredient-btn {
    right: 10px;
  }
}

.ingredient-row input,
.ingredient-row select {
  width: 100%;
  height: 42px;
  padding: 0.75rem 1rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  background: #f8fafc;
  transition: all 0.2s ease;
  box-sizing: border-box;
  line-height: 1.2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ingredient-row select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23475569' stroke-width='2'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem;
  padding-right: 2.5rem;
}

.quantity-input {
  text-align: right;
  padding-right: 0.5rem !important;
  height: 42px !important;
}

.ingredient-row input:hover,
.ingredient-row select:hover {
  border-color: #3b82f6;
  background: #fff;
}

.ingredient-row input:focus,
.ingredient-row select:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  outline: none;
  background: #fff;
}

.ingredient-row label {
  font-size: 0.9rem;
  font-weight: 500;
  color: #1e293b;
  margin-bottom: 0.5rem;
  display: block;
}

.ingredient-actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

.add-ingredient-btn {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.25rem;
  background: #3b82f6;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 1rem;
}

.add-ingredient-btn:hover {
  background: #2563eb;
  transform: translateY(-1px);
}

.add-ingredient-btn svg {
  width: 1.25rem;
  height: 1.25rem;
}

.remove-ingredient-btn {
  padding: 0.5rem;
  background: #fee2e2;
  border: none;
  border-radius: 8px;
  color: #ef4444;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-ingredient-btn:hover {
  background-color: rgba(239, 68, 68, 0.1);
}

.remove-ingredient-btn svg {
  width: 1.25rem;
  height: 1.25rem;
}

/* Input Group Styling */
.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.input-group label {
  white-space: nowrap;
}

/* Quantity Input Specific */
.quantity-input {
  text-align: right;
  padding-right: 0.5rem !important;
}

/* Unit Select Specific */
.unit-select {
  min-width: 100px;
}

/* Action Buttons Card */
.action-buttons-card {
  grid-area: action-buttons;
  grid-column: 1 / -1;
  width: 100%;
  background: white;
  border-radius: 12px;
  padding: 1.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  gap: 1rem;
  transition: all 0.3s ease;
}

.action-buttons-card button {
  padding: 0.875rem 1.5rem;
  border-radius: 8px;
  font-size: 0.95rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.action-buttons-card .cancel-btn {
  background: #f1f5f9;
  border: 2px solid #e2e8f0;
  color: #64748b;
}

.action-buttons-card .cancel-btn:hover {
  background: #e2e8f0;
  border-color: #cbd5e1;
  transform: translateY(-1px);
}

.action-buttons-card .create-btn {
  background: #3b82f6;
  border: none;
  color: white;
  box-shadow: 0 2px 4px rgba(59, 130, 246, 0.1);
}

.action-buttons-card .create-btn:hover {
  background: #2563eb;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(59, 130, 246, 0.2);
}

.action-buttons-card .create-btn svg,
.action-buttons-card .cancel-btn svg {
  width: 18px;
  height: 18px;
  stroke-width: 2;
}

/* Add loading state styles */
.action-buttons-card .create-btn.loading {
  position: relative;
  background: #60a5fa;
  cursor: not-allowed;
}

.action-buttons-card .create-btn.loading:hover {
  transform: none;
}

.action-buttons-card .create-btn.loading .spinner {
  animation: spin 1s linear infinite;
  margin-right: 0.5rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .action-buttons-card {
    width: 95%;
    padding: 1.25rem;
    flex-direction: column-reverse;
  }

  .action-buttons-card button {
    width: 100%;
    justify-content: center;
  }
}

/* View mode styles */
.recipe-form.view-mode input,
.recipe-form.view-mode textarea,
.recipe-form.view-mode select,
.recipe-form.view-mode .add-ingredient-btn,
.recipe-form.view-mode .remove-ingredient-btn,
.recipe-form.view-mode .image-upload-container input[type="file"],
.recipe-form.view-mode .checkbox-group input[type="checkbox"] {
  pointer-events: none;
  opacity: 0.8;
  cursor: default;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.recipe-form.view-mode .image-upload-container label,
.recipe-form.view-mode .toggle-switch {
  cursor: default;
  pointer-events: none;
}

.recipe-form.view-mode .image-upload-label {
  display: none;
}

.recipe-form.view-mode .toggle-switch.disabled {
  opacity: 0.7;
}

.recipe-form.view-mode .image-preview img {
  cursor: default;
}

.recipe-form.view-mode .action-buttons-card {
  justify-content: center;
}

.recipe-form.view-mode .cancel-btn {
  background-color: #2196F3;
  color: white;
}

.recipe-form.view-mode .cancel-btn:hover {
  background-color: #1976D2;
}

.recipe-form.view-mode .add-ingredient-btn,
.recipe-form.view-mode .remove-ingredient-btn {
  display: none;
}

/* Toast Messages */
.toast-message {
  position: fixed;
  top: 20px;
  right: 20px;
  padding: 12px 24px;
  border-radius: 8px;
  z-index: 1000;
  animation: slideIn 0.3s ease-in-out;
  max-width: 400px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.toast-message.success {
  background-color: #22c55e;
  color: white;
  border-left: 4px solid #16a34a;
}

.toast-message.error {
  background-color: #ef4444;
  color: white;
  border-left: 4px solid #dc2626;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Table styles */
.ingredients-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.ingredients-table th,
.ingredients-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid #e2e8f0;
}

.ingredients-table th {
  font-weight: 600;
  color: #475569;
  background-color: #f8fafc;
}

/* Column widths */
.ingredient-name {
  width: 40%;
}

.ingredient-quantity {
  width: 25%;
  min-width: 150px;
  white-space: nowrap;
}

.ingredient-cost {
  width: 25%;
  min-width: 120px;
  white-space: nowrap;
}

.ingredient-actions {
  width: 10%;
  text-align: right;
}
