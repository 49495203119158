:root {
  /* Modern Color Palette */
  --bg-gradient-start: #f6f7ff;
  --bg-gradient-end: #e9eeff;
  --primary: #6366f1;
  --primary-dark: #4f46e5;
  --secondary: #0ea5e9;
  --accent: #f43f5e;
  --success: #10b981;
  --warning: #f59e0b;
  --error: #ef4444;
  --text-primary: #1e293b;
  --text-secondary: #64748b;
  --text-light: #94a3b8;
  --surface-light: rgba(255, 255, 255, 0.8);
  --surface-white: rgba(255, 255, 255, 0.95);
  --border-light: rgba(255, 255, 255, 0.5);
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.07);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  --blur-effect: blur(16px);
}

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient(135deg, var(--bg-gradient-start), var(--bg-gradient-end));
  min-height: 100vh;
  color: var(--text-primary);
  font-family: "Futura", "Trebuchet MS", Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

/* Modern Card Styles */
.glass-card {
  background: var(--surface-light);
  backdrop-filter: var(--blur-effect);
  border: 1px solid var(--border-light);
  border-radius: 16px;
  box-shadow: var(--shadow-md);
}

.neo-card {
  background: var(--surface-white);
  border-radius: 16px;
  box-shadow: 
    8px 8px 16px rgba(0, 0, 0, 0.07),
    -8px -8px 16px rgba(255, 255, 255, 0.8);
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  color: var(--text-primary);
  font-weight: 600;
  line-height: 1.3;
  margin: 0;
}

h1 { font-size: 2.5rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.75rem; }
h4 { font-size: 1.5rem; }
h5 { font-size: 1.25rem; }
h6 { font-size: 1rem; }

/* Modern Button Styles */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  font-weight: 500;
  transition: all 0.3s ease;
  cursor: pointer;
  border: none;
  gap: 0.5rem;
  font-family: inherit;
}

.btn-primary {
  background: linear-gradient(135deg, var(--primary), var(--primary-dark));
  color: white;
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.3);
}

.btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(99, 102, 241, 0.4);
}

.btn-secondary {
  background: var(--surface-white);
  color: var(--primary);
  border: 2px solid var(--primary);
}

.btn-secondary:hover {
  background: var(--primary);
  color: white;
  transform: translateY(-2px);
}

.btn-success {
  background: linear-gradient(135deg, var(--success), #059669);
  color: white;
  box-shadow: 0 4px 12px rgba(16, 185, 129, 0.3);
}

.btn-success:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(16, 185, 129, 0.4);
}

.btn-danger {
  background: linear-gradient(135deg, var(--error), #dc2626);
  color: white;
  box-shadow: 0 4px 12px rgba(239, 68, 68, 0.3);
}

.btn-danger:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 16px rgba(239, 68, 68, 0.4);
}

/* Form Styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-primary);
  font-weight: 500;
}

.form-input {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 2px solid transparent;
  background: var(--surface-white);
  border-radius: 12px;
  font-size: 1rem;
  transition: all 0.3s ease;
  color: var(--text-primary);
  box-shadow: var(--shadow-sm);
  font-family: inherit;
}

.form-input:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 4px rgba(99, 102, 241, 0.1);
}

.form-select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%236366f1'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1.5rem;
  padding-right: 2.5rem;
}

/* Table Styles */
.table-container {
  overflow-x: auto;
  background: var(--surface-white);
  border-radius: 16px;
  box-shadow: var(--shadow-md);
}

.modern-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.modern-table th {
  background: var(--primary);
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.875rem;
  letter-spacing: 0.05em;
  padding: 1rem;
}

.modern-table td {
  padding: 1rem;
  border-bottom: 1px solid rgba(99, 102, 241, 0.1);
  color: var(--text-primary);
}

.modern-table tr:last-child td {
  border-bottom: none;
}

.modern-table tr:hover td {
  background: rgba(99, 102, 241, 0.05);
}

/* Utility Classes */
.text-primary { color: var(--text-primary); }
.text-secondary { color: var(--text-secondary); }
.text-light { color: var(--text-light); }
.text-success { color: var(--success); }
.text-error { color: var(--error); }
.text-center { text-align: center; }

.mb-1 { margin-bottom: 0.5rem; }
.mb-2 { margin-bottom: 1rem; }
.mb-3 { margin-bottom: 1.5rem; }
.mb-4 { margin-bottom: 2rem; }

.mt-1 { margin-top: 0.5rem; }
.mt-2 { margin-top: 1rem; }
.mt-3 { margin-top: 1.5rem; }
.mt-4 { margin-top: 2rem; }

.p-4 { padding: 1rem; }

/* Animation Classes */
.fade-in {
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Design */
@media (max-width: 768px) {
  .btn {
    width: 100%;
  }
  
  .form-input,
  .form-select {
    font-size: 16px; /* Prevents zoom on mobile */
  }
}
