.data-manager {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.section h2 {
  margin-top: 0;
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0;
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.action-buttons {
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
}

.action-card {
  padding: 1.5rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.action-card h3 {
  margin: 0 0 1rem 0;
  color: #333;
  font-size: 1.1rem;
}

.button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.button:hover {
  background: #0056b3;
}

.data-buttons {
  display: flex;
  gap: 1rem;
}

.icon-btn {
  padding: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.2s;
}

.icon-btn:hover {
  transform: scale(1.1);
}

.btn-icon {
  width: 24px;
  height: 24px;
  opacity: 0.8;
}

.import-history {
  margin-top: 2rem;
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.import-history h3 {
  margin-bottom: 1rem;
  color: #333;
  font-size: 1.2rem;
}

.history-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.history-item {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: #f8f9fa;
}

.history-item.success {
  border-left: 4px solid #28a745;
}

.history-item.error {
  border-left: 4px solid #dc3545;
}

.history-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
}

.history-date {
  color: #666;
  font-size: 0.9rem;
}

.history-status {
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  font-weight: bold;
}

.history-status.success {
  color: #28a745;
}

.history-status.error {
  color: #dc3545;
}

.history-summary {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.history-section {
  margin: 1rem 0;
  padding: 0.5rem;
  border-radius: 4px;
  background: #f8f9fa;
}

.history-section strong {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-size: 0.95rem;
}

.history-section.error {
  background: #fff5f5;
  border-left: 3px solid #dc3545;
}

.history-section.error .recipe-item {
  border-color: #ffcdd2;
  color: #dc3545;
}

.recipe-item {
  padding: 0.5rem;
  margin: 0.25rem 0;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recipe-item:hover {
  background: #f8f9fa;
}

.recipe-list {
  max-height: 200px;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.recipe-list::-webkit-scrollbar {
  width: 6px;
}

.recipe-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.recipe-list::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.recipe-list::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.error-message {
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.page-title-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.page-title {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
}

/* Responsive Design */
@media (max-width: 992px) {
  .action-buttons {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .action-card {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .data-manager {
    padding: 10px;
  }
}